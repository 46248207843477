<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">

    <el-row :gutter="24">
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>日新增意向</span>
            <el-tooltip effect="dark" content="意向提交数量" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num">{{pageData.dailyApplyCount}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">
              总意向数量
            </el-col>
            <el-col :span="12" class="text-right">
              {{pageData.totalApplyCount}}
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>日访问量</span>
            <el-tooltip effect="dark" content="访问量" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num"> {{pageData.dailyVisitingCount}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">
              总访问量
            </el-col>
            <el-col :span="12" class="text-right">
              {{pageData.totalVisitingCount}}
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>日新增圈子数量</span>
            <el-tooltip effect="dark" content="圈子数量" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num">{{pageData.dailyCommunityCount}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">
              总圈子数量
            </el-col>
            <el-col :span="12" class="text-right">
              {{pageData.totalCommunityCount}}
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <el-card class="box-card" shadow="never">
          <div slot="header">
            <span>新增用户</span>
            <el-tooltip effect="dark" content="用户数量" placement="top">
              <svg-icon icon-class="ask" class="float-right cursor-pointer" />
            </el-tooltip>
          </div>
          <div class="num">{{pageData.dailyUserCount}}</div>
          <el-divider></el-divider>
          <el-row class="bot">
            <el-col :span="12">
              总用户
            </el-col>
            <el-col :span="12" class="text-right">
              {{pageData.totalUserCount}}
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import { debounce } from '@/utlis';
import { datacentre } from '@/service/statistic.js';

export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      pageData: {
        dailyApplyCount: 0,
        dailyCommunityCount: 0,
        dailyUserCount: 0,
        dailyVisitingCount: 0,
        totalApplyCount: 0,
        totalCommunityCount: 0,
        totalUserCount: 0,
        totalVisitingCount: 0
      },
      chart: null,
      resizeHandler: null,
      radio1: '今日',
      datevalue1: ''
    };
  },
  computed: {
    ...mapGetters([
      'sidebarOpened'
    ])
  },
  watch: {
    sidebarOpened() {
      this.resizeHandler();
    }
  },
  created() {
    this.dataBinding();
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById('home-traffic-chart'), 'macarons');
      this.chart.setOption({
        grid: {
          left: '50px',
          right: '20px',
          top: '10px',
          bottom: '35px'
        },
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {},
        series: [{
          name: '销量',
          type: 'bar',
          data: [5888, 3838, 15880, 12888, 18888, 16888, 5888, 3838, 15880, 12888, 18888, 16888]
        }]
      });

    },
    initResizeEvent() {
      window.addEventListener('resize', this.resizeHandler);
    },
    //数据加载
    dataBinding() {
      this.loading = true;
      datacentre().then(res => {
        this.pageData = res;
        console.log(this.pageData);
        this.loading = false;
      });
    }
  },
  mounted() {
    const _this = this;
    _this.resizeHandler = debounce(() => {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    _this.initChart();
    _this.initResizeEvent();
  }
};
</script>
<style lang="scss">
.box-card {
  border: 0;
  margin-bottom: 24px;
  .el-card__header {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-card__body {
    padding: 12px;
  }
  .num {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .el-divider--horizontal {
    margin: 8px 0;
  }
  .bot {
    font-size: 14px;
  }
  .traffic-title {
    margin: 0;
    margin-bottom: 10px;
  }
  .traffic-li {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
  }
  .traffic-num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 8px;
    &.hot {
      background-color: rgba($color: #209e91, $alpha: 0.8);
      color: #ffffff;
    }
  }
}
</style>
